<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <!-- logo -->
          <vuexy-logo />

          <h2 class="brand-text text-primary ml-1">Mymovingway</h2>
        </b-link>

        <b-card-title class="mb-1">
         Yeniden Katılma Mailini Onayla
          <img
            class="biceps-icon"
            :src="require('@/assets/images/elements/biceps.png')"
            alt=""
          /> 
        </b-card-title>
        <b-card-text
          ><b>Sayın {{ firstName }} {{ lastName }} ,</b></b-card-text
        >
        <b-card-text class="mb-2">
          <b>{{ companyName }}</b> firmasına katılmayı
          onaylayınız.
        </b-card-text>

        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form
            class="auth-forgot-password-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- submit button -->
            <b-button variant="primary" block type="submit">
              <b>{{ $t("Join") }}</b>
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
      <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BCard,
  BLink,
  BCardText,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import { mounted } from "vue-echarts";

export default {
  components: {
    VuexyLogo,
    BCard,
    BLink,
    BCardText,
    BCardTitle,
    BFormGroup,
    BFormInput,
    BButton,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      // validation
      required,
      email,
      personInviteId: null,
      joinType: null,
      submitValid: true,
      companyName: "",
      firstName: "",
      lastName: "",
    };
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success && this.submitValid) {
            this.$database.AccountService.invite_confirm_check(
              this.personInviteId
            ).then((res) => {
              if (res.IsSuccess === true || res.IsSuccess === 1) {
                this.$functions.Messages.success("You Joined");
                this.$store.dispatch("auth/logout");
              } else {
                this.$store.dispatch("auth/logout");
              }
            });
        }
      });
    },

    getFacilityConfirmData() {
      this.$database.FacilityService.facility_confirm(
        this.personInviteId
      ).then((res) => {      
        if (res.IsSuccess === true || res.is_success === 1) {
          this.firstName = res.Result.FirstName;
          this.lastName = res.Result.LastName;
          this.companyName= res.Result.CompanyBrancName
        }
      });
    },


    // getCompanyBranchName() {
    //   this.$database.CustomerService.get_company_branch_name_by_person_invite_id(
    //     this.personInviteId
    //   ).then((res) => {
    //     if (res.is_success === true || res.is_success === 1) {
    //       this.companyName = res.result.name;
    //     }
    //   });
    // },
    // getPersonName() {
    //   this.$database.CustomerService.get_person_name_by_person_invite_id(
    //     this.personInviteId
    //   ).then((res) => {
    //     if (res.is_success === true || res.is_success === 1) {
    //       this.name = res.result.name;
    //       this.lastName = res.result.last_name;
    //     }
    //   });
    // },

  },
  created() {
    //query params format
    this.personInviteId = this.$route.params.param1;
    this.joinType = this.$route.params.param2;

    //url params format
    // this.personInviteId = this.$route.params.linkParams.split("&")[0];
    // this.joinType = this.$route.params.linkParams.split("&")[1];
  },
  mounted() {
    // this.checkInviteValidity();
    // this.getCompanyBranchName();
    // this.getPersonName();

    this.getFacilityConfirmData();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
.biceps-icon {
  width: 24px;
  height: 24px;
  margin-bottom: 7px;
}
</style>
